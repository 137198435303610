<template>
  <div class="intract-list-sheet">
    <v-bottom-sheet
      scrollable
      :width="$vuetify.breakpoint.mdAndDown ? '100%' : '60%'"
      :value="visible"
      @click:outside="$emit('close')"
    >
      <v-card>
        <!-- <v-card-title v-if="title" class="pb-2">{{ title }}</v-card-title> -->
        <v-card-title class="pb-2" v-if="title">
          {{ title }}<v-spacer></v-spacer>

          <v-btn icon @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-subtitle v-if="description" class="pb-2 pt-1">{{
          description
        }}</v-card-subtitle>
        <v-card-text class="px-2">
          <slot name="list">
            <v-list>
              <v-list-item
                v-for="(item, index) in list"
                :key="index"
                @click="
                  () => {
                    $emit('close');
                    item.action();
                  }
                "
              >
                <v-list-item-icon v-if="item.icon"
                  ><v-icon size="20">{{ item.icon }}</v-icon></v-list-item-icon
                >
                <v-img v-if="item.image" class="mr-3" max-width="30px" max-height="30px" :src="item.image"></v-img>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle
                    class="text-wrap"
                    v-if="item.subtitle"
                    >{{ item.subtitle }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </slot>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>
<script>
export default {
  name: "IntractListSheet",
  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
};
</script>